/* You can add global styles to this file, and also import other style files */

@import '@angular/cdk/overlay-prebuilt.css';

:root {
  --font-family: Montserrat, Avenir, 'Avenir Next LT Pro', Corbel, 'URW Gothic', source-sans-pro, sans-serif;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  display: grid;
  background-color: #faf9f6;
  font-family: var(--font-family);
}

h2 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 800;
}

h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

h4 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

p,
li {
  margin: 0;
  max-width: 60ch;
}

a {
  color: currentColor;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

strong {
  font-weight: 600;
}

router-outlet {
  position: absolute;
}

html {
  font-size: 16px;
}

@media (max-width: 576px) {
  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  html {
    font-size: 15px;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 20px;
  }
}
